import * as React from "react";
import { Floatbox } from "../components/next/floatbox";
import "../components/next/layout.css";
import { Header, HeaderLeft, HeaderLogo, HeaderLogoImage, HeaderMenu, IconLink, NavigationItem, SocialIcon, SocialLinks } from "../components/next/header";
import skywardLogo from "../images/next/Logo.png";
import linkedIn from "../images/next/LinkedIn.png";
import pathOfAgesImage from "../images/next/content01.png";
import subscribeBackground from "../images/next/BG.png";
import { H1, HorizontalLine, Italic, Paragraph } from "../components/next/typography";
import { Aside, Background, CallToAction, Game, Section, Link } from "../components/next/content";
import { Footer, FooterItem, FooterLink, FooterSection } from "../components/next/footer";
import { Claims, Claim, Newsletter, NewsletterForm, NewsletterLabel, NewsletterEmail, NewsletterSubmit, NewsletterSignup } from "../components/next/newsletter";
import { About, Card, Cards, Company, Team } from "../components/next/about";

import imageDennis from "../images/next/sasse-dennis.webp";
import imageAndreas from "../images/next/fischer-andreas.webp";
import imageBenjamin from "../images/next/baindl-benjamin.webp";
import imageRichard from "../images/next/schmidbauer-richard.webp";

import imageLinkedIn from "../images/next/s_linkedin.webp";
import imageGithub from "../images/next/s_github.webp";
import imageArtstation from "../images/next/s_artstation.webp";
import imageTibetrea from "../images/next/tibetrea_t.png";

import { SEO } from "../components/seo";

const AboutPage = () => {
    return (<>
        <Floatbox id="header">
            <Header>
                <HeaderLogo>
                    <IconLink to="/" target="_blank">
                        <HeaderLogoImage src={skywardLogo} alt="Skyward Assembly Logo" />
                    </IconLink>
                </HeaderLogo>
                <HeaderMenu>
                    <HeaderLeft>
                        <NavigationItem>
                            <Link to="/">Games</Link>
                        </NavigationItem>
                        <NavigationItem>
                            <Link className="active" to="/about">Team</Link>
                        </NavigationItem>
                    </HeaderLeft>
                </HeaderMenu>
                <SocialLinks>
                    <IconLink to="https://www.linkedin.com/company/skyward-assembly/">
                        <SocialIcon src={linkedIn} alt="LinkedIn logo" />
                    </IconLink>
                </SocialLinks>
            </Header>
        </Floatbox>
        <Section>
            <About>
                <Company>
                    <H1>The Company</H1>
                    <Paragraph>Skyward Assembly was founded in 2022 by four game enthusiasts and industry veterans. “Gameplay First” sums up our attitude pretty nicely. We love playing games. We love making games. We love strategy games and we love working as a team.</Paragraph>
                    <Paragraph>For us, it’s only possible to make great games with passion and experience. Mutual respect und humility as well as equality and inclusiveness are the pillar of how we operate as a team. It’s in our DNA.</Paragraph>
                    <Paragraph>Let’s build worlds together!</Paragraph>
                </Company>
                <Team>
                    <H1>The Team</H1>
                    <Cards>
                        <Card name="Dennis Sasse"
                            picture={{ url: imageDennis }}
                            socials={[
                                { icon: { url: imageGithub }, url: "https://github.com/Tzirrit" },
                                { icon: { url: imageLinkedIn }, url: "https://www.linkedin.com/in/dennissasse/" }
                            ]}
                            title="Co Founder, CEO, Producer"
                        />
                        <Card name="Andreas Fischer"
                            picture={{ url: imageAndreas }} socials={[
                                { icon: { url: imageGithub }, url: "https://github.com/Vengarioth" },
                                { icon: { url: imageLinkedIn }, url: "https://www.linkedin.com/in/fischer-andreas/" }
                            ]}
                            title="Co Founder, CTO"
                        />
                        <Card name="Benjamin Baindl"
                            picture={{ url: imageBenjamin }} socials={[
                                { icon: { url: imageTibetrea }, url: "https://www.tibetrea.de/crew1" },
                                { icon: { url: imageLinkedIn }, url: "https://www.linkedin.com/in/benjamin-baindl-226073235/" }
                            ]}
                            title="Co Founder, Game Design, Music and Sound"
                        />
                        <Card name="Richard Schmidbauer"
                            picture={{ url: imageRichard }} socials={[
                                { icon: { url: imageArtstation }, url: "https://visionvortex.artstation.com/" },
                                { icon: { url: imageLinkedIn }, url: "https://www.linkedin.com/in/visionvortex/" }
                            ]}
                            title="Co Founder, Art Director"
                        />
                    </Cards>
                </Team>
            </About>
        </Section>
        <Section>
        <Newsletter style={{ backgroundImage: `url(${subscribeBackground})` }}>
            <Claims>
                <Claim>{'#procedural'}</Claim>
                <Claim>{'#replayable'}</Claim>
                <Claim>{'#worldbuilding'}</Claim>
            </Claims>
            <NewsletterForm target="_blank" action="https://skywardassembly.us17.list-manage.com/subscribe/post" id="mailform">
                <NewsletterLabel htmlFor="MERGE0">Stay up to date with our newsletter</NewsletterLabel>
                <input type="hidden" name="u" value="6c88f810f7fa0850eb9bcab10" />
                <input type="hidden" name="id" value="4848dc1c84" />
                <NewsletterSignup>
                    <NewsletterEmail type="email" autoComplete="email" name="MERGE0" id="MERGE0" placeholder="Enter your email address" />
                    <NewsletterSubmit type="submit" value="Subscribe" id="button_mail" />
                </NewsletterSignup>
            </NewsletterForm>
        </Newsletter>
        </Section>
        <Section>
        <Footer>
            <FooterSection>
                <FooterItem>
                    <FooterLink role="button" to="/imprint">IMPRINT</FooterLink>
                </FooterItem>
                <FooterItem>
                    <FooterLink to="/privacy-policy">PRIVACY POLICY</FooterLink>
                </FooterItem>
                <FooterItem>
                    <FooterLink role="button" to="/Presskit.zip">PRESSKIT</FooterLink>
                </FooterItem>
            </FooterSection>
        </Footer>
        </Section>
    </>);
};

export default AboutPage;
export const Head = () => (<SEO />);
